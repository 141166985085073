import { Token, LoggedUserDatas } from "./manageLocalStorage";
import { Configuration } from "../configuration";
import { Navigate } from "react-router-dom";

/**
 * Redirect route
 *
 * @param {object} dataObj
 * @param {any} dataObj.inputRoute
 * @param {int} dataObj.routeType
 *
 * @returns {object}
 */
const RedirectRoute = ({ children, dataObj }) => {
  if (
    Token.hasToken() &&
    LoggedUserDatas.getUserDatas("roleName") ===
      Configuration.routeTypes.customer &&
    [Configuration.routeTypes.admin, Configuration.routeTypes.noAuth].includes(
      dataObj?.routeType
    )
  ) {
    return <Navigate to="/userDashboard" />;
  } else if (
    Token.hasToken() &&
    LoggedUserDatas.getUserDatas("roleName") ===
      Configuration.routeTypes.admin &&
    [
      Configuration.routeTypes.customer,
      Configuration.routeTypes.noAuth,
    ].includes(dataObj?.routeType)
  ) {
    return <Navigate to="/adminDashboard" />;
  } else if (
    !Token.hasToken() &&
    dataObj?.routeType !== Configuration.routeTypes.noAuth
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RedirectRoute;
