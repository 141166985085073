import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Token,
  LoggedUserDatas,
} from "../../../../services/manageLocalStorage";
import { store } from "../../../../redux/store";
import { useDoLogoutMutation } from "../../../../redux/services/login";
import LogoIcon1 from "../../../../assets/images/logoIcon.svg";

const Logout = ({ isShow = true, setClose }) => {
  const navigate = useNavigate();
  const [doLogout2] = useDoLogoutMutation();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Logout functionality
   */
  const doLogout1 = () => {
    Token.destroyToken();
    LoggedUserDatas.removeUserDatas();
    store.dispatch({ type: "USER_LOGGED_OUT" });
    navigate("/");
  };

  /**
   * Logout functionality
   */
  async function doLogout3() {
    setIsLoading(true);
    const inputObj = {
      token: Token.getToken(),
    };
    doLogout2(inputObj);
    doLogout1();
    setIsLoading(false);
  }

  return (
    <>
      <Modal
        show={isShow}
        onHide={() => {
          setClose();
        }}
      >
        <Modal.Header closeButton className="fw-bold">
          Ready to Leave?
        </Modal.Header>
        <Modal.Body>
          {!isLoading ? (
            <>
              <div>
                Click 'Logout' below to securely end your current session
              </div>
            </>
          ) : (
            <>
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="rotate-odin-image1">
                    <img className="top-icon" src={LogoIcon1} alt="Logo" />
                  </div>
                </div>
              </>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary"
            name="deleteBtn1"
            disabled={isLoading}
            onClick={(event) => {
              event.preventDefault();
              setClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            name="deleteBtn1"
            disabled={isLoading}
            onClick={(event) => {
              event.preventDefault();
              doLogout3();
            }}
          >
            Logout
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Logout;
