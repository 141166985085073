import { createHashRouter, RouterProvider } from "react-router-dom";
import {
  noAuthRoutes,
  EmailLoginRoute,
  userAuthRouteList,
  AdminDashboardRoute,
  LoggedInDashboardRoute,
  adminAuthRouteList,
} from "./services/routes";
import NoAuthLayout from "./components/layouts/noAuthLayout";
import AuthUserLayout from "./components/layouts/authUserLayout";
import AdminUserLayout from "./components/layouts/adminUserLayout";
import RedirectRoute from "./services/redirectRoute";
import { Configuration } from "./configuration";

const App = () => {
  const router = createHashRouter([
    {
      element: (
        <>
          <RedirectRoute
            dataObj={{
              routeType: Configuration.routeTypes.noAuth,
            }}
          >
            <NoAuthLayout />
          </RedirectRoute>
        </>
      ),
      errorElement: <EmailLoginRoute />,
      children: noAuthRoutes,
    },
    {
      element: (
        <>
          <RedirectRoute
            dataObj={{
              routeType: Configuration.routeTypes.customer,
            }}
          >
            <AuthUserLayout />
          </RedirectRoute>
        </>
      ),
      errorElement: <LoggedInDashboardRoute />,
      children: userAuthRouteList,
    },
    {
      element: (
        <>
          <RedirectRoute
            dataObj={{
              routeType: Configuration.routeTypes.admin,
            }}
          >
            <AdminUserLayout />
          </RedirectRoute>
        </>
      ),
      errorElement: <AdminDashboardRoute />,
      children: adminAuthRouteList,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
