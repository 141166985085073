import CryptoJS, { AES } from "crypto-js";

const Token = {
  hasToken: () => typeof localStorage.getItem("token") === "string",
  setToken: (token) => {
    const encrypted = encryptData(token);
    localStorage.setItem("token", encrypted);
  },
  getToken: () => {
    const encryptData = localStorage.getItem("token");

    return decryptedData(encryptData);
  },
  destroyToken: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("registerToken");
  },
  hasRefreshToken: () =>
    typeof localStorage.getItem("refreshToken") === "string",
  setRefreshToken: (token) => {
    const encrypted = encryptData(token);
    localStorage.setItem("refreshToken", encrypted);
  },
  getRefreshToken: () => {
    const encryptData = localStorage.getItem("refreshToken");

    return decryptedData(encryptData);
  },
  hasRegisterToken: () =>
    typeof localStorage.getItem("registerToken") === "string",
  setRegisterToken: (token) => {
    const encrypted = encryptData(token);
    localStorage.setItem("registerToken", encrypted);
  },
  getRegisterToken: () => {
    const encryptData = localStorage.getItem("registerToken");

    return decryptedData(encryptData);
  },
  destroyRegisterToken: () => {
    localStorage.removeItem("registerToken");
  },
};

const LoggedUserDatas = {
  hasUserDatas: () => typeof localStorage.getItem("userData") === "string",
  setUserDatas: (datas) => {
    const encrypted = encryptData(JSON.stringify(datas));
    localStorage.setItem("userData", encrypted);
  },
  getUserDatas: (property) => {
    let userDatas = localStorage.getItem("userData");
    userDatas = decryptedData(userDatas);
    if (
      typeof property !== "string" ||
      typeof userDatas !== "string" ||
      (typeof userDatas === "string" && userDatas === "")
    )
      return "";
    const userObj = JSON.parse(userDatas);
    if (typeof userObj[property] === "undefined") return "";

    return userObj[property];
  },
  removeUserDatas: () => localStorage.removeItem("userData"),
  setUserProperties: (userPropArr) => {
    let userProp = undefined;
    let userDatas = localStorage.getItem("userData");
    userDatas = decryptedData(userDatas);
    if (
      !Array.isArray(userPropArr) ||
      typeof userDatas !== "string" ||
      (typeof userDatas === "string" && userDatas === "")
    ) {
      return false;
    }
    let userObj = JSON.parse(userDatas);
    for (userProp of userPropArr) {
      userObj[userProp.property] = userProp.userValue;
    }
    const encrypted = encryptData(JSON.stringify(userObj));
    localStorage.setItem("userData", encrypted);
  },
  getUserLoginColor: () => {
    let color = localStorage.getItem("userLoginColor");
    color = typeof color === "string" ? color : "";
    color = decryptedData(color);

    return typeof color === "string" ? color : "";
  },
  setUserLoginColor: (colorVal = "") => {
    const color = typeof colorVal === "string" ? colorVal : "";
    const encrypted = encryptData(color);
    localStorage.setItem("userLoginColor", encrypted);
  },
  getUserDataObj: () => {
    let userDatas = localStorage.getItem("userData");
    userDatas = decryptedData(userDatas);
    if (
      typeof userDatas !== "string" ||
      (typeof userDatas === "string" && userDatas === "")
    ) {
      return undefined;
    }
    return JSON.parse(userDatas);
  },
};

/**
 * Encrypt data
 */
const encryptData = (inputTxt = "") => {
  if (!(typeof inputTxt === "string" && inputTxt.trim() !== "")) {
    return "";
  }
  const encrypted = AES.encrypt(
    inputTxt,
    process.env.REACT_APP_ENCRYPT_SECRET_KEY
  );

  return encrypted.toString();
};

/**
 * Decrypted data
 */
const decryptedData = (encryptedData = "") => {
  if (!(typeof encryptedData === "string" && encryptedData.trim() !== "")) {
    return "";
  }
  return AES.decrypt(
    encryptedData,
    process.env.REACT_APP_ENCRYPT_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
};

const RemberMeData = {
  hasRememberDatas: () =>
    typeof localStorage.getItem("rememberMe") === "string",
  setRememberDatas: (datas) => {
    const encrypted = encryptData(JSON.stringify(datas));
    localStorage.setItem("rememberMe", encrypted);
  },
  getRememberDatas: (property) => {
    let userDatas = localStorage.getItem("rememberMe");
    userDatas = decryptedData(userDatas);
    if (
      typeof property !== "string" ||
      typeof userDatas !== "string" ||
      (typeof userDatas === "string" && userDatas === "")
    )
      return "";
    const userObj = JSON.parse(userDatas);
    if (typeof userObj[property] === "undefined") return "";

    return userObj[property];
  },
  removeRememberDatas: () => localStorage.removeItem("rememberMe"),
  setRememberProperties: (userPropArr) => {
    let userProp = undefined;
    let userDatas = localStorage.getItem("rememberMe");
    userDatas = decryptedData(userDatas);
    if (
      !Array.isArray(userPropArr) ||
      typeof userDatas !== "string" ||
      (typeof userDatas === "string" && userDatas === "")
    ) {
      return false;
    }
    let userObj = JSON.parse(userDatas);
    for (userProp of userPropArr) {
      userObj[userProp.property] = userProp.userValue;
    }
    const encrypted = encryptData(JSON.stringify(userObj));
    localStorage.setItem("rememberMe", encrypted);
  },
};

export { Token, LoggedUserDatas, RemberMeData };
