import { createSlice } from "@reduxjs/toolkit";

const referenceSlice = createSlice({
  name: "reference",
  initialState: {
    referenceObj: undefined,
    referencePaginationObj: {
      offset: 0,
      total: 0,
      pageCount: 0,
      currentPage: 0,
      sortKey: undefined,
      sortType: undefined,
    },
    navSidebarIsShow: false,
    customerObj: undefined,
    customerPaginationObj: {
      offset: 0,
      total: 0,
      pageCount: 0,
      currentPage: 0,
      sortKey: undefined,
      sortType: undefined,
    },
  },
  reducers: {
    setReferenceObj: (state, action) => {
      state.referenceObj = action.payload;
    },
    setReferencePaginationObj: (state, action) => {
      state.referencePaginationObj = action.payload;
    },
    resetReferenceObj: (state, action) => {
      state.referenceObj = undefined;
    },
    resetReferencePaginationObj: (state, action) => {
      state.referencePaginationObj = {
        offset: 0,
        total: 0,
        pageCount: 0,
        currentPage: 0,
        sortKey: undefined,
        sortType: undefined,
      };
    },
    setNavSidebarIsShow: (state, action) => {
      state.navSidebarIsShow = action.payload;
    },
    setcustomerObj: (state, action) => {
      state.customerObj = action.payload;
    },
    setcustomerPaginationObj: (state, action) => {
      state.customerPaginationObj = action.payload;
    },
    resetCustomerObj: (state, action) => {
      state.customerObj = undefined;
    },
    resetCustomerPaginationObj: (state, action) => {
      state.customerPaginationObj = {
        offset: 0,
        total: 0,
        pageCount: 0,
        currentPage: 0,
        sortKey: undefined,
        sortType: undefined,
      };
    },
  },
});

export const {
  setReferenceObj,
  setReferencePaginationObj,
  resetReferenceObj,
  resetReferencePaginationObj,
  setNavSidebarIsShow,
  setcustomerObj,
  setcustomerPaginationObj,
  resetCustomerObj,
  resetCustomerPaginationObj,
} = referenceSlice.actions;
export default referenceSlice.reducer;
