/* eslint-disable react-hooks/exhaustive-deps */
import LeftMenu from "../../../components/user/leftMenu";
import TopMenu from "../../../components/user/topMenu";
import Footer from "../../user/footer";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";

const AuthUserLayout = () => {
  return (
    <>
      <Suspense fallback={<></>}>
        <div id="wrapper">
          <LeftMenu />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <TopMenu />
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default AuthUserLayout;
