/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LogoIcon1 from "../../../assets/images/logoIcon.svg";
import { useSelector } from "react-redux";

const LeftMenu = () => {
  const [screenSize, setScreenSize] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const navSidebarIsShow = useSelector(
    (state) => state.reference.navSidebarIsShow
  );

  /**
   * Detect the size
   */
  function updateSize() {
    setScreenSize(window.innerWidth);
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  /**
   * Get the menu toggle class
   */
  const getMenuToggleClass = (inputSize) => {
    if (inputSize <= 767 && !navSidebarIsShow) {
      return "toggled";
    }

    return "";
  };

  return (
    <>
      <ul
        className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${getMenuToggleClass(screenSize)}`}
        id="accordionSidebar"
      >
        <a
          className={`sidebar-brand d-flex align-items-center justify-content-center`}
          href="index.html"
          onClick={(event) => {
            event.preventDefault();
            navigate("/adminDashboard");
          }}
        >
          <div className="sidebar-brand-icon">
            <img src={LogoIcon1} alt="Cubet" />
          </div>
          <div className="sidebar-brand-text mx-3">Connections</div>
        </a>

        <hr className="sidebar-divider my-0" />

        <li
          className={`nav-item  ${location?.pathname === "/userDashboard" ? "active" : ""}`}
        >
          <a
            className="nav-link"
            href="index.html"
            onClick={(event) => {
              event.preventDefault();
              navigate("/userDashboard");
            }}
          >
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>

        <hr className="sidebar-divider my-0" />

        <li
          className={`nav-item  ${location?.pathname === "/userReference" ? "active" : ""}`}
        >
          <a
            className="nav-link"
            href="index.html"
            onClick={(event) => {
              event.preventDefault();
              navigate("/userReference");
            }}
          >
            <i className="fas fa-fw fa-file-invoice-dollar"></i>
            <span>Referrals</span>
          </a>
        </li>

        <hr className="sidebar-divider my-0" />
      </ul>
    </>
  );
};

export default LeftMenu;
