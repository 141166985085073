/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";

const NoAuthLayout = () => {
  return (
    <>
      <Suspense fallback={<></>}>
        <div className={`bg-gradient-primary vh-100`}>
          <Container>
            <Outlet />
          </Container>
        </div>
      </Suspense>
    </>
  );
};

export default NoAuthLayout;
