import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import referenceReducer from "./slices/refrenceSlice";
import { noAuthSplitApi } from "./noAuthSplitApi";
import { userRegisterSplitApi } from "./userRegisterSplitApi";
import { authUserSplitApi } from "./authUserSplitApi";

export const appReducer = combineReducers({
  reference: referenceReducer,
  noAuthSplitApi: noAuthSplitApi.reducer,
  userRegisterSplitApi: userRegisterSplitApi.reducer,
  authUserSplitApi: authUserSplitApi.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export const middlewares = [
  thunk,
  noAuthSplitApi.middleware,
  userRegisterSplitApi.middleware,
  authUserSplitApi.middleware,
];
