import { configureStore, Tuple } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { rootReducer, middlewares } from "./reducers"; // Import your combined reducers

const store = configureStore({
  middleware: () => new Tuple(...middlewares),
  reducer: rootReducer,
});

setupListeners(store.dispatch);

export { store };
