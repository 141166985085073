/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { LoggedUserDatas } from "../../../services/manageLocalStorage";
import undrawProfile1 from "../../../assets/images/undraw_profile.svg";
import Logout from "./logout";
import { useNavigate } from "react-router-dom";
import { setNavSidebarIsShow } from "../../../redux/slices/refrenceSlice";
import { useDispatch, useSelector } from "react-redux";

const TopMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [canShowMenu, setCanShowMenu] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const navSidebarIsShow = useSelector(
    (state) => state.reference.navSidebarIsShow
  );

  /**
   * Get first username
   */
  const getFirstUsername = () => {
    const lastName =
      typeof LoggedUserDatas.getUserDatas("lastName") === "string"
        ? LoggedUserDatas.getUserDatas("lastName")
        : "";
    const firstName =
      typeof LoggedUserDatas.getUserDatas("firstName") === "string"
        ? LoggedUserDatas.getUserDatas("firstName")
        : "";
    let first = typeof firstName?.[0] === "string" ? firstName[0] : "";
    first = first.toUpperCase() + firstName.slice(1);

    let last = typeof lastName?.[0] === "string" ? lastName[0] : "";
    last = last.toUpperCase() + lastName.slice(1);

    return first + " " + last;
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={(event) => {
            event.preventDefault();
            dispatch(setNavSidebarIsShow(!navSidebarIsShow));
          }}
          aria-label="Mobile Menu"
        >
          <i className="fa fa-bars"></i>
        </button>
        <ul className="navbar-nav ml-auto">
          <li
            className={`nav-item dropdown no-arrow ${canShowMenu ? "show" : ""}`}
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(event) => {
                event.preventDefault();
                setCanShowMenu(!canShowMenu);
              }}
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small overflow-hidden text-overflow-ellipse white-space-nowrap w-118px text-end">
                {getFirstUsername()}
              </span>
              <img
                className="img-profile rounded-circle"
                src={undrawProfile1}
                alt="User profile"
              />
            </a>
            <div
              className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${canShowMenu ? "show" : ""}`}
              aria-labelledby="userDropdown"
            >
              <a
                className="dropdown-item"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/userEditProfile");
                }}
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-dark"></i>
                Profile
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/userSettings");
                }}
              >
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-dark"></i>
                Settings
              </a>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
                onClick={(event) => {
                  event.preventDefault();
                  setIsShowDelete(true);
                }}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-dark"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {isShowDelete ? (
        <>
          <Logout
            isShow={isShowDelete}
            setClose={() => {
              setIsShowDelete(false);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TopMenu;
