import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Token, LoggedUserDatas } from "../services/manageLocalStorage";
import { store } from "./store";
import { Configuration } from "../configuration";

/**
 * Logout functionality
 */
const doLogout = () => {
  Token.destroyToken();
  LoggedUserDatas.removeUserDatas();
  store.dispatch({ type: "USER_LOGGED_OUT" });
  window.location.reload();
};

const PrimaryQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    headers.set("Authorization", `Bearer ${Token.getToken()}`);

    return headers;
  },
});

/**
 * Refresh token implementation
 */
const refreshToken = async (inputObj) => {
  const result = await PrimaryQuery(
    {
      url: Configuration.backend.refreshToken,
      method: "post",
      body: { refreshToken: Token.getRefreshToken() },
    },
    inputObj?.api,
    inputObj?.extraOptions
  );
  if (
    typeof result?.data?.token === "string" &&
    typeof result?.data?.refreshToken === "string"
  ) {
    Token.setToken(result.data.token);
    Token.setRefreshToken(result.data.refreshToken);
  } else {
    doLogout();
  }
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await PrimaryQuery(args, api, extraOptions);
  if (
    result?.error?.data?.statusCode === 401 &&
    result?.error?.data?.message === "Invalid token"
  ) {
    await refreshToken({ args, api, extraOptions });
    result = await PrimaryQuery(args, api, extraOptions);
  }

  return result;
};

export const authUserSplitApi = createApi({
  reducerPath: "authUserSplitApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
