import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Token } from "../services/manageLocalStorage";

const PrimaryQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    headers.set("Authorization", `Bearer ${Token.getRegisterToken()}`);

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await PrimaryQuery(args, api, extraOptions);

  return result;
};

export const userRegisterSplitApi = createApi({
  reducerPath: "userRegisterSplitApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
