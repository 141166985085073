import { noAuthSplitApi } from "../noAuthSplitApi";
import { Configuration } from "../../configuration";

export const loginApi = noAuthSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    doLogin: builder.mutation({
      query: (userobj) => {
        return {
          url: Configuration.backend.login,
          method: "post",
          body: userobj,
        };
      },
    }),
    changePassword: builder.mutation({
      query: (bodyObj) => {
        return {
          url: Configuration.backend.changePassword,
          method: "post",
          body: bodyObj,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (bodyObj) => {
        return {
          url: Configuration.backend.forgotPassword,
          method: "post",
          body: bodyObj,
        };
      },
    }),
    checkChangePasswordToken: builder.mutation({
      query: (bodyObj) => {
        return {
          url: Configuration.backend.changePasswordTokenCheck,
          method: "post",
          body: bodyObj,
        };
      },
    }),
    doLogout: builder.mutation({
      query: (bodyObj) => {
        return {
          url: Configuration.backend.doLogout,
          method: "post",
          body: bodyObj,
        };
      },
    }),
  }),
});

export const {
  useDoLoginMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useCheckChangePasswordTokenMutation,
  useDoLogoutMutation,
} = loginApi;
