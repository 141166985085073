import { lazy } from "react";

export const EmailLoginRoute = lazy(() => import("../pages/login"));
export const AdminDashboardRoute = lazy(
  () => import("../pages/loggedIn/admin/dashboard")
);
export const LoggedInDashboardRoute = lazy(
  () => import("../pages/loggedIn/user/dashboard")
);
const AdminReferenceRoute = lazy(
  () => import("../pages/loggedIn/admin/reference")
);
const AdminReferenceEditRoute = lazy(
  () => import("../pages/loggedIn/admin/reference/editReferral")
);
const AdminEditProfileViewRoute = lazy(
  () => import("../pages/loggedIn/admin/editProfile")
);
const UserEditProfileViewRoute = lazy(
  () => import("../pages/loggedIn/user/editProfile")
);
const UserReferenceRoute = lazy(
  () => import("../pages/loggedIn/user/reference")
);
const AdminCustomerListRoute = lazy(
  () => import("../pages/loggedIn/admin/customer")
);
const AdminSettingsRoute = lazy(
  () => import("../pages/loggedIn/admin/settings")
);
const ChangePasswordRoute = lazy(() => import("../pages/login/changePassword"));
const ForgotPasswordRoute = lazy(() => import("../pages/login/forgotPassword"));
const UserSettingsRoute = lazy(() => import("../pages/loggedIn/user/settings"));
const AdminCustomerViewRoute = lazy(
  () => import("../pages/loggedIn/admin/customer/viewCustomer")
);

export const noAuthRoutes = [
  {
    path: "/",
    element: (
      <>
        <EmailLoginRoute />
      </>
    ),
  },
  {
    path: "/changePassword",
    element: (
      <>
        <ChangePasswordRoute />
      </>
    ),
  },
  {
    path: "/forgotPassword",
    element: (
      <>
        <ForgotPasswordRoute />
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <EmailLoginRoute />
      </>
    ),
  },
];

export const userAuthRouteList = [
  {
    path: "/userDashboard",
    element: (
      <>
        <LoggedInDashboardRoute />
      </>
    ),
  },
  {
    path: "/userEditProfile",
    element: (
      <>
        <UserEditProfileViewRoute />
      </>
    ),
  },
  {
    path: "/userSettings",
    element: (
      <>
        <UserSettingsRoute />
      </>
    ),
  },
  {
    path: "/userReference",
    element: (
      <>
        <UserReferenceRoute />
      </>
    ),
  },
];

export const adminAuthRouteList = [
  {
    path: "/adminDashboard",
    element: (
      <>
        <AdminDashboardRoute />
      </>
    ),
  },
  {
    path: "/adminCustomer",
    element: (
      <>
        <AdminCustomerListRoute />
      </>
    ),
  },
  {
    path: "/adminViewCustomer",
    element: (
      <>
        <AdminCustomerViewRoute />
      </>
    ),
  },
  {
    path: "/adminSettings",
    element: (
      <>
        <AdminSettingsRoute />
      </>
    ),
  },
  {
    path: "/adminEditProfile",
    element: (
      <>
        <AdminEditProfileViewRoute />
      </>
    ),
  },
  {
    path: "/adminReference",
    element: (
      <>
        <AdminReferenceRoute />
      </>
    ),
  },
  {
    path: "/adminEditReference",
    element: (
      <>
        <AdminReferenceEditRoute />
      </>
    ),
  },
];

export const routeList = [
  ...noAuthRoutes,
  ...userAuthRouteList,
  ...adminAuthRouteList,
];
